<template>
  <b-taglist class="breadcrumbs container p-4">
    <b-tag
      v-for="(item, index) in $store.getters.breadcrumbsData"
      :key="index"
      size="is-large"
      rounded
      :type="
        $store.getters.breadcrumbsData.length == index + 1
          ? 'is-light'
          : 'is-white'
      "
    >
      <router-link :to="item" class="has-text-grey">
        <b-icon icon="home" type="is-grey" v-if="index == 0"></b-icon>
        <span v-else>{{ item.label }}</span>
      </router-link>
    </b-tag>
  </b-taglist>
</template>

<script>
export default {};
</script>
