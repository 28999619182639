<template>
  <b-button type="is-gold" :loading="loading" @click="addToCart" size="is-large"
    >Add to Cart</b-button
  >
</template>

<script>
import { Events } from "@/events.js";
export default {
  props: {
    id: {
      type: Number
    },
    custom: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    addToCart() {
      if (
        this.$store.getters.activeUser.is_client &&
        _.isNull(JSON.parse(localStorage.getItem("cart")))
      ) {
        this.$router.push({ name: "Continue As" });
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("addToCart", {
          cart_id: this.$store.getters.activeUser.cart.id,
          product_id: this.id,
          product_quantity: 1,
          method: "add",
          custom: this.custom
        })
        .then(res => {
          Events.$emit("cart-updated");
          this.$store.dispatch("displayMessage", {
            type: "is-success",
            message: res.data.message
          });
        })
        .catch(() => {
          this.$store.dispatch("displayMessage", {
            type: "is-warning",
            message: this.$store.getters.generalErrorMessage
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
