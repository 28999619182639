<template>
  <div id="" class="is-relative">
    <!-- <b-loading :is-full-page="false" v-model="loading" /> -->
    <Header />
    <div class="h-24"></div>
    <Breadcrumbs />
    <div class="container p-4">
      <div class="product mb-4">
        <b-loading :is-full-page="true" v-model="loading" />
        <div class="px-2">
          <div class="-mx-4 flex flex-wrap">
            <div class="w-1/2 px-2 flex flex-col">
              <div class="flex-1 gallery">
                <b-image
                  class="cursor-pointer"
                  :src="
                    images[index] ? images[index].thumb : $default_image_thumb
                  "
                  :placeholder="$blank_image_thumb"
                  :alt="product.slug"
                  ratio="1by1"
                  lazy
                  @click.native="showImageModal()"
                ></b-image>
                <div class="gallery-thumbs">
                  <div class="flex flex-wrap">
                    <div class="w-1/6 px-2" v-for="(img, i) in images" :key="i">
                      <b-image
                        class="gallery-thumbs-item"
                        :class="{ 'active shadow-xs': i == index }"
                        @mouseover.native="changeIndex(i)"
                        :src="img ? img.thumb : $default_image_thumb"
                        :placeholder="$blank_image_thumb"
                        :alt="product.slug"
                        ratio="1by1"
                        lazy
                      ></b-image>
                    </div>
                  </div>
                </div>
                <!-- <b-icon
                  size="is-medium"
                  icon="chevron-left"
                  @click.native="decrementIndex"
                  v-if="images.length > 1"
                  class="gallery-control gallery-control-left shadow-xs"
                ></b-icon>
                <b-icon
                  size="is-medium"
                  icon="chevron-right"
                  @click.native="incrementIndex"
                  v-if="images.length > 1"
                  class="gallery-control gallery-control-right shadow-xs"
                ></b-icon> -->
              </div>
            </div>
            <div class="w-1/2 px-2 flex flex-col">
              <div class="flex-1 p-4">
                <div class="primary-details has-text-grey mb-4">
                  <div class="is-size-5">
                    <span>{{ product.code }}</span>
                    <span class="new" v-if="product.new">new</span>
                  </div>
                  <div class="is-size-3">{{ product.name }}</div>
                  <div class="is-size-4" v-if="product.price">
                    {{ product.price }} USD
                  </div>
                </div>
                <div class="add-to-cart mb-4">
                  <AddToCart
                    :id="product.id"
                    :custom="userHasPermission('write-custom-carts')"
                    v-if="
                      (userHasPermission('write-carts') ||
                        userHasPermission('write-custom-carts')) &&
                        $store.getters.userRole != 'super-admin' &&
                        product.price != '0.00' &&
                        product.price
                    "
                  />
                </div>
                <div class="variant-details has-text-grey">
                  <b-collapse class="card" animation="slide" :open="true">
                    <div
                      slot="trigger"
                      slot-scope="props"
                      class="card-header"
                      role="button"
                    >
                      <p class="card-header-title has-text-grey">
                        Color Options
                      </p>
                      <a class="card-header-icon">
                        <b-icon
                          :icon="props.open ? 'menu-down' : 'menu-up'"
                          type="is-grey"
                        >
                        </b-icon>
                      </a>
                    </div>
                    <div class="p-4">
                      <div class="has-text-grey">
                        <div class="px-4">
                          <div class="-mx-2 flex flex-wrap">
                            <router-link
                              v-if="data.slug"
                              class="w-1/6 px-1 mb-2"
                              :class="{ 'scale shadow-xs': !$route.query.v }"
                              :to="{
                                name: 'Product',
                                params: { id: data.slug }
                              }"
                            >
                              <b-image
                                :src="
                                  data.image
                                    ? data.image.thumb
                                    : $default_image_thumb
                                "
                                :alt="data.slug"
                                ratio="1by1"
                                responsive
                                lazy
                              ></b-image>
                            </router-link>
                            <router-link
                              class="w-1/6 px-1 mb-2"
                              :class="{
                                'scale shadow-xs': $route.query.v == variant.id
                              }"
                              v-for="(variant, index) in data.variants"
                              :key="index"
                              :to="{
                                name: 'Product',
                                params: { id: data.slug },
                                query: { v: variant.id }
                              }"
                            >
                              <b-image
                                class=""
                                :src="
                                  variant.image
                                    ? variant.image.thumb
                                    : $default_image_thumb
                                "
                                :alt="variant.slug"
                                ratio="1by1"
                                lazy
                              ></b-image>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-collapse>
                </div>
                <div class="secondary-details">
                  <b-collapse
                    class="card"
                    animation="slide"
                    :open="isOpen == 'size'"
                    @open="isOpen = 'size'"
                  >
                    <div
                      slot="trigger"
                      slot-scope="props"
                      class="card-header"
                      role="button"
                    >
                      <p class="card-header-title has-text-grey">Dimensions</p>
                      <a class="card-header-icon">
                        <b-icon
                          :icon="props.open ? 'menu-down' : 'menu-up'"
                          type="is-grey"
                        >
                        </b-icon>
                      </a>
                    </div>
                    <div class="card-content">
                      <div class="content has-text-grey">
                        L {{ data.length }} x W {{ data.width }} x H
                        {{ data.height }} cm
                      </div>
                    </div>
                  </b-collapse>
                  <b-collapse
                    class="card"
                    animation="slide"
                    :open="isOpen == 'volume'"
                    @open="isOpen = 'volume'"
                  >
                    <div
                      slot="trigger"
                      slot-scope="props"
                      class="card-header"
                      role="button"
                    >
                      <p class="card-header-title has-text-grey">
                        Packaging Info
                      </p>
                      <a class="card-header-icon">
                        <b-icon
                          :icon="props.open ? 'menu-down' : 'menu-up'"
                          type="is-grey"
                        >
                        </b-icon>
                      </a>
                    </div>
                    <div class="card-content">
                      <div class="content has-text-grey">
                        <p>
                          Packed Volume: {{ data.volume_packed }} m<sup>3</sup>
                          ~
                        </p>
                        <p>
                          Knockdown:
                          {{ data.parts > 1 ? `${data.parts} pcs` : "No" }}
                        </p>
                      </div>
                    </div>
                  </b-collapse>
                  <b-collapse
                    class="card"
                    animation="slide"
                    :open="isOpen == 'weight'"
                    @open="isOpen = 'weight'"
                  >
                    <div
                      slot="trigger"
                      slot-scope="props"
                      class="card-header"
                      role="button"
                    >
                      <p class="card-header-title has-text-grey">Weight</p>
                      <a class="card-header-icon">
                        <b-icon
                          :icon="props.open ? 'menu-down' : 'menu-up'"
                          type="is-grey"
                        >
                        </b-icon>
                      </a>
                    </div>
                    <div class="card-content">
                      <div class="content has-text-grey">
                        <p>Net Wt: {{ data.weight_net }} kg ~</p>
                        <p>Gross Wt.: {{ data.weight_gross }} kg ~</p>
                      </div>
                    </div>
                  </b-collapse>
                  <b-collapse
                    class="card"
                    animation="slide"
                    :open="isOpen == 'illuminated'"
                    @open="isOpen = 'illuminated'"
                  >
                    <div
                      slot="trigger"
                      slot-scope="props"
                      class="card-header"
                      role="button"
                    >
                      <p class="card-header-title has-text-grey">Illuminated</p>
                      <a class="card-header-icon">
                        <b-icon
                          :icon="props.open ? 'menu-down' : 'menu-up'"
                          type="is-grey"
                        >
                        </b-icon>
                      </a>
                    </div>
                    <div class="card-content">
                      <div class="content has-text-grey">
                        {{ data.is_illuminated ? "Yes" : "No" }}
                      </div>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="related mt-10" v-if="related.length">
        <div class="is-size-4 mb-4">Related Products</div>
        <div class="px-2">
          <div class="-mx-4 flex flex-wrap">
            <div
              class="w-1/4 mb-4 px-2"
              v-for="(item, index) in related"
              :key="index"
            >
              <ProductCard :data="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="modalActive" :width="800">
      <!-- <p class="image is-1by1">
        <img :src="images[index].full" :alt="images[index].public_filename">
      </p> -->
      <b-image
        :src="images[index] ? images[index].full : $default_image_thumb"
        :placeholder="
          images[index] ? images[index].thumb : $default_image_thumb
        "
        :alt="product.slug"
        ratio="1by1"
      ></b-image>
    </b-modal>
  </div>
</template>

<script>
import Header from "@/components/client/Header";
import Breadcrumbs from "@/components/client/Breadcrumbs";
import ProductCard from "@/components/client/ProductCard";
import AddToCart from "@/components/client/AddToCart";
import store from "@/store";
export default {
  data: () => ({
    loading: false,
    data: {},
    url: {},
    product: {},
    index: 0,
    images: [],
    isOpen: "size",
    related: [],
    modalActive: false
  }),
  components: {
    Header,
    Breadcrumbs,
    ProductCard,
    AddToCart
  },
  computed: {},
  watch: {
    $route(to, from) {
      this.loading = true;
      store
        .dispatch(this.$route.meta.dispatch, { id: this.$route.params.id })
        .then(res => {
          this.getRelatedData();
          if (to.params.id != from.params.id) {
            store.commit("setBreadcrumbs", {
              name: "Home",
              label: "Home",
              index: 0
            });
          }
          store.commit("addBreadcrumbs", {
            name: to.name,
            params: to.params,
            label: res.data.name,
            index: 2
          });
          document.title = `${res.data.name} - ${process.env.VUE_APP_TITLE}`;
          this.data = res.data;
          if (to.query.v) {
            let variant = _.find(res.data.variants, ["id", Number(to.query.v)]);
            if (variant) {
              this.setData(variant, res.data);
            } else {
              this.setData(res.data);
            }
          } else {
            this.setData(res.data);
          }
        })
        .catch(() => {
          this.$router.push({ name: "Home" });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  methods: {
    userHasPermission(data) {
      return this.$store.getters.userHasPermission(data);
    },
    setData(product, base) {
      // set url
      this.url = {
        name: "Product",
        params: {
          id: product.slug
        }
      };
      if (base) {
        this.url["query"] = { v: base.id };
      }
      // set product
      this.product = product;
      // set index to 0
      this.index = 0;
      //set images
      this.images = product.images.length ? product.images : [product.image];
    },
    getRelatedData() {
      store
        .dispatch(this.$route.meta.dispatch, {
          id: this.$route.params.id,
          related: true
        })
        .then(res => {
          this.related = res.data;
        });
    },
    incrementIndex() {
      if (this.index == this.images.length - 1) this.index = 0;
      else this.index++;
    },
    decrementIndex() {
      if (this.index == 0) this.index = this.images.length - 1;
      else this.index--;
    },
    changeIndex(i) {
      this.index = i;
    },
    showImageModal() {
      this.modalActive = true;
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store
        .dispatch(to.meta.dispatch, { id: to.params.id })
        .then(res => {
          next(vm => {
            vm.getRelatedData();
            store.commit("addBreadcrumbs", {
              name: to.name,
              params: to.params,
              label: res.data.name,
              index: 2
            });
            document.title = `${res.data.name} - ${process.env.VUE_APP_TITLE}`;
            vm.data = res.data;
            if (to.query.v) {
              let variant = _.find(res.data.variants, [
                "id",
                Number(to.query.v)
              ]);
              if (variant) {
                vm.setData(variant, res.data);
              } else {
                vm.setData(res.data);
              }
            } else {
              vm.setData(res.data);
            }
          });
        })
        .catch(() => {
          next({ name: "Home" });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
$baseMargin: 0.75rem;
$baseColor: #ad9861;
.scale {
  // transform: scale(1.1);
  z-index: 1;
}

.gallery {
  position: relative;
  .gallery-control {
    position: absolute;
    bottom: $baseMargin;
    cursor: pointer;
    color: $baseColor;
  }
  .gallery-control-left {
    right: 2.75rem + $baseMargin;
  }
  .gallery-control-right {
    right: $baseMargin;
  }
}

.new {
  margin-left: $baseMargin;
  padding: 1px 5px;
  border: 2px solid $baseColor;
  color: $baseColor;
  text-transform: uppercase;
  border: 2px solid $baseColor;
}

.related {
  min-height: 100vh;
}
</style>
